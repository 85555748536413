import {ReactElement} from "react";
import {redirectTo} from "../../lib/utility";
import {DesktopMenuFeaturedProduct} from "./DesktopMenuFeaturedProduct";
import {DesktopMenuFeaturedBlogPost} from "./DesktopMenuFeaturedBlogPost";
import {Transition} from "@headlessui/react";
import {MenuEntryModel} from "./models/MenuEntryModel";
import {FormattedMessage} from "react-intl";

export interface DesktopMenuEntryProps {
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	show: boolean,
	menuEntry: MenuEntryModel
	trackNavigationEvent: (level1: string, level2: string) => void
}

export function DesktopMenuEntry(props: DesktopMenuEntryProps): ReactElement {

	return (
		<Transition
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			show={props.show}
			className={`tw-z-20 tw-absolute tw-container tw-rounded tw-bg-white tw-shadow-desktop-menu tw-text-black tw-font-gotham tw-grid tw-grid-cols-5 tw-gap-4`}>
			{props.menuEntry.rows.map((row, index) => (
				<div className={"tw-mt-8 tw-ml-16 tw-mb-8"} key={index}>
					<div className={"tw-uppercase tw-font-bold tw-py-4 tw-px-2"}>{row.title}</div>
					{row.entries.map((entry, subIndex) => (
						<div className={"tw-p-2 hover:tw-bg-homeComponentsBg tw-cursor-pointer"} key={subIndex}
							 onClick={() => {
								 props.trackNavigationEvent(props.menuEntry.title, entry.title)
								 redirectTo(entry.url)
							 }}>{entry.title}</div>
					))}
				</div>
			))}
			<div className={"tw-col-span-2 tw-bg-homeComponentsBg tw-flex tw-flex-col tw-p-8"}>
				{props.menuEntry.featuredProduct &&
					<DesktopMenuFeaturedProduct
						trackNavigationEvent={props.trackNavigationEvent}
						featuredProduct={props.menuEntry.featuredProduct}/>
				}
				<div className={"tw-flex tw-justify-end tw-mt-16 tw-font-bold tw-pr-2 tw-pb-2"} onClick={() => {
					props.trackNavigationEvent('Blog', '');
					redirectTo("/blog")
				}}>
                    <span className={"tw-cursor-pointer hover:tw-underline"}>
                        <FormattedMessage id={'menu.blog.all.stories'}/>
                    </span>
				</div>
				<DesktopMenuFeaturedBlogPost
					trackNavigationEvent={props.trackNavigationEvent}
					blogPost={props.menuEntry.featuredBlogPost}/>
			</div>
		</Transition>
	);

}