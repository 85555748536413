import {ReactElement, useEffect, useState} from "react";
import {getActionButtonMenu, getNftHoldersOffersButtonMenu, getBasicMenu, getBlogMenu, getMenu, MenuItem} from "lib/api/menuApi";
import {DesktopMenuEntry} from "./DesktopMenuEntry";
import {MenuModel} from "./models/MenuModel";
import {redirectTo} from "lib/utility";
import {BlogMenuModel} from "./models/BlogMenuModel";
import {BlogMenuEntry} from "./BlogMenuEntry";
import {ActionButtonMenuModel} from "./models/ActionButtonMenuModel";

export function DesktopMenu(): ReactElement {

	const [showMenu, setShowMenu] = useState(false);
	const [openMenu, setOpenMenu] = useState<null | number>(null);
	const [menu, setMenu] = useState<MenuModel>();
	const [blogMenu, setBlogMenu] = useState<BlogMenuModel>();
	const [actionButtonMenu, setActionButtonMenu] = useState<ActionButtonMenuModel>();
	const [nftHoldersOffersButtonMenu, setNftHoldersOffersButtonMenu] = useState<ActionButtonMenuModel>();
	const [basicMenu, setBasicMenu] = useState<MenuItem[]>();
	const [isLoading, setIsLoading] = useState(true);
	const [delayHandler, setDelayHandler] = useState<any>();

	useEffect(() => {

		const fetchMenu = async () => {
			const basic = await getBasicMenu();
			const blog = await getBlogMenu();
			const desktop = await getMenu();
			const actionButton = await getActionButtonMenu();
			const nftHoldersOffersButton = await getNftHoldersOffersButtonMenu();

			setBasicMenu(basic);
			setBlogMenu(blog);
			setMenu(desktop);
			setActionButtonMenu(actionButton)
			setNftHoldersOffersButtonMenu(nftHoldersOffersButton)

			setIsLoading(false);
		}

		fetchMenu();

	}, [])


	function menuItemMouseEnter(menuItem: number) {
		setDelayHandler(setTimeout(() => {
			setShowMenu(true);
			setOpenMenu(menuItem);
		}, 200));
	}

	function menuItemLeave() {
		clearTimeout(delayHandler);
		setShowMenu(false);
	}

	const trackNavigationEvent = (level1: string, level2: string) => {
		// @ts-ignore
		window.dataLayer.push({
			'event': 'Tracking Event',
			'category': 'Navigation Tracking',
			'action': 'Navigation Level 1 ' + level1,
			'label': 'Navigation Level 2 ' + level2
		});
	}

	if (isLoading) {
		return (
			<>
				<div className={"tw-flex tw-flex-1 tw-text-black tw-justify-center tw-uppercase tw-text-2xl"}>
					{basicMenu?.map((menuEntry, index) => (
						<div
							className={`tw-cursor-pointer tw-p-8 tw-font-bold hover:tw-text-grey ${index === basicMenu?.length - 1 ? 'tw-font-gotham-black-italic' : 'tw-font-gotham-black'}`}
							key={index}
							onClick={() => redirectTo(menuEntry.url)}
						>{menuEntry.label}</div>
					))}
				</div>
			</>
		)
	}

	return (
		<>
			<div className={"tw-flex tw-flex-1 tw-text-black tw-justify-center tw-uppercase tw-text-2xl"}>
				{menu?.entries.map((entry, index) => (
					<div
						className={`tw-cursor-pointer tw-p-8 tw-font-bold tw-font-gotham-black ${openMenu === index && showMenu ? 'tw-text-grey' : ''} `}
						key={index}
						onMouseEnter={() => menuItemMouseEnter(index)}
						onMouseLeave={() => menuItemLeave()}
						onClick={() => {
							trackNavigationEvent(entry.title, '');
							redirectTo(entry.url)
						}}
					>{entry.title}</div>
				))}
				{nftHoldersOffersButtonMenu &&
					<div
						className={'tw-cursor-pointer tw-p-8 tw-font-bold tw-font-gotham-black'}
						key={nftHoldersOffersButtonMenu.title}
						onClick={() => redirectTo(nftHoldersOffersButtonMenu?.url)}
					>{nftHoldersOffersButtonMenu.title}</div>
				}
				{blogMenu &&
					<div
						className={`tw-cursor-pointer tw-p-8 tw-font-bold tw-font-gotham-black-italic ${openMenu === menu?.entries.length && showMenu ? 'tw-text-grey' : ''} `}
						onMouseEnter={() => menuItemMouseEnter(menu ? menu?.entries.length : 5)}
						onMouseLeave={() => menuItemLeave()}
						onClick={() => {
							trackNavigationEvent(blogMenu?.title, '')
							redirectTo(blogMenu.url)
						}}
					>{blogMenu.title}</div>
				}
				{actionButtonMenu &&
                    <div
                        className={`tw-cursor-pointer tw-font-bold ${actionButtonMenu.blackFriday ? 'tw-p-4' : 'tw-p-8'}`}
                        key={actionButtonMenu.title}
                        onClick={() => window.open(actionButtonMenu?.url, '_blank')}
                    ><div className={`${actionButtonMenu.blackFriday ? 'tw-bg-red tw-text-white tw-p-3 tw-font-gotham-black' : 'tw-font-gotham-black-italic tw-text-menuActionButton'}`}>
						{actionButtonMenu.title}
					</div>
					</div>
				}
			</div>
			{menu?.entries.map((menuEntry, index) => (
				<DesktopMenuEntry
					key={index}
					onMouseEnter={() => setShowMenu(true)}
					onMouseLeave={() => menuItemLeave()}
					show={openMenu === index && showMenu}
					menuEntry={menuEntry}
					trackNavigationEvent={trackNavigationEvent}
				/>
			))}
			{blogMenu &&
				<BlogMenuEntry
					onMouseEnter={() => setShowMenu(true)}
					onMouseLeave={() => menuItemLeave()}
					show={openMenu === menu?.entries.length && showMenu}
					blogMenu={blogMenu}
				/>
			}
		</>
	)

}
