import {ReactElement} from "react";
import {FeaturedProductModel} from "./models/FeaturedProductModel";
import {redirectTo} from "../../lib/utility";

export interface DesktopMenuFeaturedProductProps {
    featuredProduct: FeaturedProductModel
    trackNavigationEvent: (level1: string, level2: string) => void
}

export function DesktopMenuFeaturedProduct(props: DesktopMenuFeaturedProductProps) : ReactElement {

    const isOnSale = props.featuredProduct.badge === "badge_sale" && props.featuredProduct.salePrice;

    return(
        <div className={"tw-flex tw-flex-1 tw-border tw-border-inactiveGrey tw-p-4 tw-cursor-pointer"} onClick={() => {
            props.trackNavigationEvent(props.featuredProduct.name, '');
            redirectTo(props.featuredProduct.url)
        }}>
            {isOnSale &&
                <div className={"tw-absolute tw-right-4"}>
                    <div className={"tw-bg-red tw-text-white tw-text-3xl tw-font-semibold tw-py-2 tw-px-4"}>
                        <p>{Math.round(100 - (props.featuredProduct.salePrice / props.featuredProduct.retailPrice * 100))}%</p>
                    </div>
                    <div className={"tw-absolute tw-right-0"}>
                        <svg height="10" width="10" fill="#D8D4CF">
                            <polygon points="0,10 10,0 0,0"/>
                        </svg>
                    </div>
                </div>
            }
            <div>
                <img alt="" src={props.featuredProduct.imgUrl+"?preview"} className={"tw-max-h-desktopMenuPreviewCard"} />
            </div>
            <div className={"tw-pl-8"}>
                <div className={"tw-text-2xl tw-font-semibold tw-text-black tw-pr-12"}>
                    <span>{props.featuredProduct.name}</span>
                </div>
                <div className={"tw-text-base tw-py-2"}>
                    <span>{props.featuredProduct.packagingType}</span>
                </div>
                <div className={"tw-pt-2"}>
                    <span className={`${isOnSale ? 'tw-text-red' : 'tw-text-black'} tw-font-semibold tw-text-3xl`}>CHF {isOnSale ? props.featuredProduct.salePrice : props.featuredProduct.retailPrice}</span>
                    <span className={"tw-text-base tw-text-grey tw-ml-2"}>{props.featuredProduct.pricePerUnit}</span>
                </div>
                {isOnSale &&
                    <div className={"tw-line-through tw-font-bold tw-text-xl tw-text-grey tw-mt-1"}>
                        <span>statt {props.featuredProduct.retailPrice}</span>
                    </div>
                }
            </div>
        </div>
    )

}
