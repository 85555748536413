import {ReactElement} from "react";
import {BlogPostModel} from "./models/BlogPostModel";
import {redirectTo} from "lib/utility";
import parse from "html-react-parser";

export interface DesktopMenuFeaturedBlogPostProps {
    blogPost: BlogPostModel
    trackNavigationEvent: (level1: string, level2: string) => void
}

export function DesktopMenuFeaturedBlogPost(props: DesktopMenuFeaturedBlogPostProps) : ReactElement {

    return(
        <div className={"tw-flex tw-flex-1 tw-border tw-border-inactiveGrey tw-cursor-pointer"} onClick={() => {
            props.trackNavigationEvent(props.blogPost.title, '')
            redirectTo(props.blogPost.url)
        }}>
            <div>
                <img alt="blog-preview" src={props.blogPost.img} className={"tw-max-h-desktopMenuPreviewCard"} />
            </div>
            <div className={"tw-flex-col"}>
                <div className={"tw-pr-4 tw-pt-4 tw-pl-8"}>
                    <span className={"tw-font-semibold tw-text-2xl"}>{props.blogPost.title}</span>
                </div>
                <div className={"tw-pl-8 tw-pt-2"}>
                    <span>{parse(props.blogPost.teaserText)}</span>
                </div>
            </div>
        </div>
    );

}
