import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "./lib/BlLocale";
import {DesktopMenu} from "./components/desktop-menu/DesktopMenu";
import {BundleEntry} from "./components/bundle-entry/BundleEntry";

const target = document.getElementById('desktop-menu');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if(target) {
    ReactDOM.render(
        <BundleEntry
            bundleName="desktop-menu"
            locale={localeCode}
            prefetchTranslations={[
                "menu.blog.newest.post",
                "menu.blog.liked"
            ]}
        >
                <DesktopMenu />
        </BundleEntry>,
        document.getElementById('desktop-menu')
    );
}