import {ReactElement} from "react";
import {redirectTo} from "../../lib/utility";
import {DesktopMenuFeaturedBlogPost} from "./DesktopMenuFeaturedBlogPost";
import {Transition} from "@headlessui/react";
import {BlogMenuModel} from "./models/BlogMenuModel";
import {FormattedMessage} from "react-intl";

export interface BlogMenuEntryProps {
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	show: boolean,
	blogMenu: BlogMenuModel
}

export function BlogMenuEntry(props: BlogMenuEntryProps): ReactElement {

	const trackNavigationEvent = (title: string) => {
		// @ts-ignore
		window.dataLayer.push({
			'event': 'Tracking Event',
			'category': 'Navigation Tracking',
			'action': 'Navigation Level 1 ' + title,
			'label': 'Navigation Level 2' + title
		});
	}

	return (
		<Transition
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			show={props.show}
			className={`tw-z-20 tw-absolute tw-container tw-rounded tw-bg-white tw-shadow-desktop-menu tw-text-black tw-font-gotham tw-grid tw-grid-cols-5 tw-gap-4`}>
			{props.blogMenu.rows.map((row, index) => (
				<div className={"tw-mt-8 tw-ml-16 tw-mb-8"} key={index}>
					<div className={"tw-uppercase tw-font-bold tw-py-4 tw-px-2"}>{row.title}</div>
					{row.entries.map((entry, subIndex) => (
						<div className={"tw-p-2 hover:tw-bg-homeComponentsBg tw-cursor-pointer"} key={subIndex}
							 onClick={() => redirectTo(entry.url)}>{entry.title}</div>
					))}
				</div>
			))}
			<div className={"tw-col-span-2 tw-bg-homeComponentsBg tw-flex tw-flex-col tw-p-8"}>
				<div className={"tw-flex tw-justify-start tw-font-bold tw-pr-2 tw-pb-2"}>
					<FormattedMessage id={'menu.blog.newest.post'}/>
				</div>
				<DesktopMenuFeaturedBlogPost
					trackNavigationEvent={trackNavigationEvent}
					blogPost={props.blogMenu.newestPost}/>
				<div className={"tw-flex tw-justify-start tw-mt-16 tw-font-bold tw-pr-2 tw-pb-2"}>
					<FormattedMessage id={'menu.blog.liked'}/>
				</div>
				<DesktopMenuFeaturedBlogPost
					trackNavigationEvent={trackNavigationEvent}
					blogPost={props.blogMenu.popularPost}/>
			</div>
		</Transition>
	);

}
